<template>
  <div>
    <v-dialog width="80%" v-model="dialog" scrollable>
      <v-card class="tariff-modal">
        <v-card-title>
          {{ $t('tariff.add-tariff') }}
        </v-card-title>

        <v-divider />

        <v-card-text>

          <v-row>
            <v-col>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      :label="$t('name')"
                      v-model="form.name"
                      hide-details
                      :disabled="readonly"
                      :rules="[
                          commonRules.required,
                      ]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      :label="$t('external-reference')"
                      v-model="form.externalReference"
                      hide-details
                      clearable
                      :disabled="readonly"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <available-select
                      :label="$t('currency')"
                      type="currency"
                      v-model="form.currency"
                      :disabled="readonly"
                      :rules="[
                          commonRules.required,
                      ]"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                      :label="$t('network')"
                      :items="[
                          {text: 'ZEborne', value: 'internal'},
                          {text: 'Gireve', value: 'gireve'},
                          {text: 'Hubject', value: 'hubject'},
                      ]"
                      :rules="[
                          commonRules.required,
                      ]"
                      v-model="form.network"
                      :disabled="readonly"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      :label="$t('operator-id')"
                      v-model="form.operatorId"
                      clearable
                      :disabled="readonly"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      :label="$t('partner-operator-id')"
                      v-model="form.partnerOperatorId"
                      clearable
                      :disabled="readonly"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                      :label="$t('charging-point.power-type')"
                      :items="[
                          {text: 'AC 1', value: 'AC_1_PHASE'},
                          {text: 'AC 3', value: 'AC_3_PHASE'},
                          {text: 'DC', value: 'DC'},
                      ]"
                      clearable
                      v-model="form.powerType"
                      :disabled="readonly"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch
                      :label="$t('organization.cpo-private')"
                      clearable
                      v-model="form.isCpoPrivate"
                      :disabled="readonly"
                  />
                </v-col>
              </v-row>

            </v-col>
          </v-row>

          <headline class="mt-8">Règles :</headline>

          <div v-if="form.rules.length === 0">
            Pas de règle...
          </div>

          <v-row  v-for="(element, i) in form.rules" :key="'elem-' + i">
            <v-col>
              <v-card>
                <v-card-text>
                  <div class="text-right">
                    <v-btn v-if="i > 0" x-small icon @click="moveRuleUp(i)">
                      <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                    <v-btn v-if="i < form.rules.length - 1" x-small icon @click="moveRuleDown(i)">
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </div>
                  <v-row>
                    <v-col v-for="(price_component, j) in element.priceComponents" :key="i+'-'+j">
                      {{ $t('tariff.component-type') }} : {{ $t('enums.tariff-dimension-type.' + price_component.type) }}<br />
                      {{ $t('tariff.component-price') }} : {{ price_component.price }}<br />
                      <template v-if="price_component.stepSize !== null">
                        {{ $t('tariff.component-step-size') }} : {{ price_component.stepSize }}<br />
                      </template>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="4" v-for="(field, k) in element.restriction" :key="i+'-'+k">
                            <template v-if="k === 'daysOfWeek'">
                              {{ $t('enums.tariff-restriction.days') }} :
                              <template v-for="(day, l) in field" >
                                <template v-if="l > 0">, </template>
                                <b :key="i+'-'+k+'-'+l">{{ $t('enums.days.' + day) }}</b>
                              </template>
                          </template>
                          <template v-else-if="['startDate', 'endDate'].includes(k)">
                            {{ $t('enums.tariff-restriction-fields.' + k.replace('_', '-')) }} : <b>{{ field|formatDate($t('format_date')) }}</b>
                          </template>
                          <template v-else>
                            {{ $t('enums.tariff-restriction-fields.' + k.replace('_', '-')) }} : <b>{{ field }}</b>
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-card-actions>
                  <v-row class="ma-0">
                    <v-col class="text-right">
                      <v-btn small @click="editRule(i)">
                        {{ $t('edit') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <v-btn @click="addRule" small :disabled="readonly">
                {{ $t('tariff.add-rule') }}
              </v-btn>
            </v-col>
          </v-row>


          <headline class="mt-8">Description :</headline>


          <v-row>
            <v-col>

              <v-tabs
                  v-model="lang"
              >
                <v-tab
                    v-for="(lg, i) in languages"
                    :key="lg"
                >
                  {{ form.label[i].language }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="lang">
                <v-tab-item
                    v-for="(lg, i) in languages"
                    :key="lg"
                >
                  <v-row>
                    <v-col>
                      <v-textarea
                          :label="$t('label')"
                          v-model="form.label[i].value"
                          hide-details
                          :disabled="readonly"
                          auto-grow
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      Version HTML:
                      <wysiwyg-field
                          v-model="form.richLabel[i].value"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                  :label="$t('tariff.url')"
                  v-model="form.url"
                  hide-details
                  :disabled="readonly"
              />
            </v-col>
          </v-row>


        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-row class="ma-0">
            <v-col>
              <v-btn text @click="deleteTariff" type="button">
                {{ $t('delete') }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="text-right">
              <v-btn text @click="dialog=false" class="mr-3" type="button">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary" v-if="!readonly"  :disabled="!canSave" type="button" @click="save">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="70%" v-model="addRuleDialog">
      <v-card class="tariff-add-rule" v-if="elementForm">
        <v-card-title>
          {{ $t('tariff.add-rule') }}
        </v-card-title>

        <v-divider />

        <v-card-text class="mt-5">

          <v-row>
            <v-col md="4">
              <v-row>
                <v-col>
                  <div class="float-right">
                    <v-btn icon x-small @click="addPriceComponent" :disabled="readonly">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <headline>
                    {{ $t('tariff.price') }}
                  </headline>

                  <v-row class="price-components">
                    <v-col cols="12" v-for="(priceComponent, i) in elementForm.priceComponents" :key="i">

                      <v-divider v-if="i > 0" class="mb-4" />

                      <div>
                        <v-btn
                            @click="removePriceComponent(priceComponent)"
                            v-if="elementForm.priceComponents.length > 1"
                            icon
                            class="float-right"
                            x-small
                            :disabled="readonly"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <v-row>
                        <v-col>
                          <v-select
                              :label="$t('tariff.component-type')"
                              v-model="priceComponent.type"
                              :items="tariffDimensionTypeTreated"
                              item-text="text"
                              item-value="value"
                              persistent-hint
                              :hint="priceComponentHintType(priceComponent)"
                              :disabled="readonly"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                              :label="$t('tariff.component-price')"
                              v-model.number="priceComponent.price"
                              :min="0"
                              type="number"
                              persistent-hint
                              :hint="$t('tariff.component-price-hint')"
                              :disabled="readonly"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="priceComponent.type !== 'FLAT'">
                        <v-col>
                          <v-text-field
                              :label="$t('tariff.component-step-size')"
                              v-model.number="priceComponent.stepSize"
                              persistent-hint
                              :hint="$t('tariff.component-step-size-hint')"
                              type="number"
                              :disabled="readonly"
                          >
                            <template #append>
                              {{ priceComponentHintStepSize(priceComponent) }}
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-divider vertical />

            <v-col>
              <v-row>

                <v-col>
                  <headline>
                    {{ $t('tariff.restriction') }}
                  </headline>

                  <v-row>
                    <v-col v-for="field in fields" :key="field">
                      <v-checkbox
                          :label="$t('enums.tariff-restriction.' + field)"
                          v-model="filters[field]"
                          :disabled="readonly" />
                    </v-col>
                  </v-row>

                  <v-row class="restriction-row">
                    <v-col md="6" v-if="filters.time">
                      <v-row>
                        <v-col class="col-title">
                          {{ $t('enums.tariff-restriction.time') }}
                        </v-col>
                        <v-col>
                          <time-picker
                              :label="$t('enums.tariff-restriction-fields.startTime')"
                              v-model="elementForm.restriction.startTime"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                        <v-col>
                          <time-picker
                              :label="$t('enums.tariff-restriction-fields.endTime')"
                              v-model="elementForm.restriction.endTime"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="6" v-if="filters.date">
                      <v-row>
                        <v-col class="col-title">
                          {{ $t('enums.tariff-restriction.date') }}
                        </v-col>
                        <v-col>
                          <date-picker
                              :label="$t('enums.tariff-restriction-fields.startDate')"
                              v-model="elementForm.restriction.startDate"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                        <v-col>
                          <date-picker
                              :label="$t('enums.tariff-restriction-fields.endDate')"
                              v-model="elementForm.restriction.endDate"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="6" v-if="filters.kwh">
                      <v-row>
                        <v-col class="col-title">
                          {{ $t('enums.tariff-restriction.kwh') }}
                        </v-col>
                        <v-col>
                          <v-text-field
                              :label="$t('enums.tariff-restriction-fields.minKwh')"
                              type="number"
                              v-model.number="elementForm.restriction.minKwh"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                              :label="$t('enums.tariff-restriction-fields.maxKwh')"
                              type="number"
                              v-model.number="elementForm.restriction.maxKwh"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="6" v-if="filters.kw">

                      <v-row>
                        <v-col class="col-title">
                          {{ $t('enums.tariff-restriction.kw') }}
                        </v-col>
                        <v-col>
                          <v-text-field
                              :label="$t('enums.tariff-restriction-fields.minPower')"
                              type="number"
                              v-model.number="elementForm.restriction.minPower"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                              :label="$t('enums.tariff-restriction-fields.maxPower')"
                              type="number"
                              v-model.number="elementForm.restriction.maxPower"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="6" v-if="filters.duration">

                      <v-row>
                        <v-col class="col-title">
                          {{ $t('enums.tariff-restriction.duration') }} ({{$t('second')}})
                        </v-col>
                        <v-col>
                          <v-text-field
                              :label="$t('enums.tariff-restriction-fields.minDuration')"
                              v-model.number="elementForm.restriction.minDuration"
                              type="number"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                              :label="$t('enums.tariff-restriction-fields.maxDuration')"
                              type="number"
                              v-model.number="elementForm.restriction.maxDuration"
                              hide-details
                              clearable
                              :disabled="readonly"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="6" v-if="filters.days">
                      <v-row>
                        <v-col class="col-title">
                          {{ $t('enums.tariff-restriction.days') }}
                        </v-col>
                        <v-col>
                          <v-select
                              :items="daysOfWeek"
                              v-model="elementForm.restriction.daysOfWeek"
                              hide-details
                              chips
                              small-chips
                              multiple
                              :disabled="readonly"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>

                  </v-row>

                </v-col>

              </v-row>
            </v-col>

          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row  class="ma-0" justify="end">
            <v-col>
              <v-btn text @click="deleteRule(elementForm)" class="mr-3">
                {{ $t('delete') }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="text-right">
              <v-btn text @click="addRuleDialog=false" class="mr-3">
                {{ $t('close') }}
              </v-btn>
              <v-btn color="primary" v-if="!readonly" @click="saveRule" :disabled="!canSaveRule">
                {{ $t(elementForm.edit ? 'save' : 'add') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AvailableSelect from "@blocks/Select/AvailableSelect.vue";
import TariffRepository from "@repository/TariffRepository";
import rulesMixin from "@mixins/RulesMixin";
import WysiwygField from "@blocks/WysiwygField";

export default {

  components: {
    AvailableSelect,
    WysiwygField,
  },

  data() {
    return {
      dialog: false,
      addRuleDialog: false,
      rulesOpened: false,

      days: [],

      languages: ['fr', 'en', 'nl'],
      fields: ['time', 'date', 'kwh', 'kw', 'duration', 'days'],
      filters: {},

      lang: 0,


      elementForm: null,

      form: {
        externalReference: null,
        network: 'internal',
        powerType: null,
        name: null,
        currency: 'EUR',
        url: null,
        richLabel: [
          {
            "language": "fr",
            "value": ""
          },
          {
            "language": "en",
            "value": ""
          },
          {
            "language": "nl",
            "value": ""
          }
        ],
        label: [
          {
            "language": "fr",
            "value": ""
          },
          {
            "language": "en",
            "value": ""
          },
          {
            "language": "nl",
            "value": ""
          }
        ],
        rules: [],
      },

      tariffDimensionType: [
        'ENERGY',
        'FLAT',
        'PARKING_TIME',
        'TIME',
        'SESSION_TIME',
      ],

      daysOfWeek: [
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
        'SUNDAY',
      ],
    }
  },

  props: {
    organizationId: String,
    tariffId: String,
    readonly: {type: Boolean, default: false},
  },

  mixins: [
      rulesMixin,
  ],

  watch: {
    filters: {
      handler() {

        if(this.rulesOpened) {
          for(let a in this.elementForm.restriction) {
            if(!this.filters[a]) {
              this.$delete(this.elementForm.restriction, a)
            }
          }
        }
      },
      deep: true,
    }
  },

  computed: {
    tariffDimensionTypeTreated() {
      return this.tariffDimensionType.map(t => ({text: this.$t('enums.tariff-dimension-type.' + t), value: t}))
    },

    canSave() {
      return this.form.rules.length > 0 && this.form.name && this.form.network
    },

    canSaveRule() {
      return this.elementForm?.priceComponents.filter(pc => pc.price !== "").length > 0
    },

  },


  methods: {
    open(tariffId) {
      if(tariffId) {
        this.tariffId = tariffId
      }
      if(this.tariffId) {
        TariffRepository.get(this.tariffId).then(tariff => {

          if(!tariff.richLabel) {
            tariff.richLabel = [
              {
                "language": "fr",
                "value": ""
              },
              {
                "language": "en",
                "value": ""
              },
              {
                "language": "nl",
                "value": ""
              }
            ]
          }

          this.form = tariff;
          this.form.rules.forEach(element => {
            for(let a in element.restriction) {
              if(element.restriction[a] === null || element.restriction[a] === '') {
                delete element.restriction[a]
              }
              if(a === 'daysOfWeek' && element.restriction[a].length === 0) {
                delete element.restriction[a]
              }
            }
          })
          this.dialog = true
        })
      } else {
        this.resetForm()
        this.dialog = true
      }
    },
    addRule() {
      this.rulesOpened = true;
      this.filters = {}
      this.elementForm = {
        priceComponents: [{
          type: 'ENERGY',
          price: 0,
          stepSize: 1,
        }],
        restriction: {
          startTime: null,
          endTime: null,
          startDate: null,
          endDate: null,
          minKwh: null,
          maxKwh: null,
          minPower: null,
          maxPower: null,
          minDuration: null,
          maxDuration: null,
          daysOfWeek: [],
        }
      }
      this.rulesOpened = true;
      this.addRuleDialog = true
    },

    editRule(index) {
      this.rulesOpened = false;
      let element = this.form.rules[index]
      element.index = index
      element.edit = true
      if(!element.restriction) {
        element.restriction = {}
      }

      this.filters = {}
      for(let a in element.restriction) {
        if(a === 'startDate' || a === 'endDate') {
          this.$set(this.filters, 'date', true)
        }
        if(a === 'startTime' || a === 'endTime') {
          this.$set(this.filters, 'time', true)
        }
        if(a === 'minPower' || a === 'maxPower') {
          this.$set(this.filters, 'power', true)
        }
        if(a === 'minKwh' || a === 'maxKwh') {
          this.$set(this.filters, 'kwh', true)
        }
        if(a === 'minDuration' || a === 'maxDuration') {
          this.$set(this.filters, 'duration', true)
        }
        if(a === 'daysOfWeek') {
          this.$set(this.filters, 'days', true)
        }
      }

      this.elementForm = this.cloneObj(element)
      this.addRuleDialog = true
      this.$nextTick(() => {
        this.rulesOpened = true;
      })
    },

    addPriceComponent() {
      this.elementForm.priceComponents.push({
        type: 'FLAT',
        price: null,
        stepSize: null,
      })
    },

    removePriceComponent(elem) {
      let indexToDelete = this.elementForm.priceComponents.findIndex(pc => pc === elem)
      this.elementForm.priceComponents.splice(indexToDelete, 1)
    },

    priceComponentHintType(element) {
      switch(element.type) {
        case 'ENERGY':
          return this.$t('tariff.energy-hint')
        case 'FLAT':
          return null
        case 'PARKING_TIME':
          return this.$t('tariff.time-not-charging-hint')
        case 'TIME':
          return this.$t('tariff.time-charging-hint')
        case 'SESSION_TIME':
          return this.$t('tariff.session-time-hint')
      }

    },

    priceComponentHintStepSize(element) {
      switch(element.type) {
        case 'ENERGY':
          return this.$t('Wh')
        case 'FLAT':
          return null
        case 'TIME':
        case 'SESSION_TIME':
        case 'PARKING_TIME':
          return this.$t('second')
      }
    },

    save() {

      this.form.organizationId = this.organizationId
      if(this.form.id) {
        this.showLoading(true)
        TariffRepository.update(this.form.id, this.form).then(result => {
          this.showLoading(false)
          this.$emit('tariff-updated')
        }).catch(err => {
          this.showLoading(false)
          this.$dialog.notify.error(err?.response?.data?.message || err.message)
        })
      } else {
        this.showLoading(true)
        TariffRepository.create(this.form).then(result => {
          this.showLoading(false)
          this.$emit('tariff-updated')
        }).catch(err => {
          this.showLoading(false)
          this.$dialog.notify.error(err?.response?.data?.message || err.message)
        })
      }
      this.dialog = false
    },

    saveRule() {
      let element = this.cloneObj(this.elementForm)

      element.priceComponents = element.priceComponents.filter(pc => pc.price)
      element.priceComponents.forEach(pc => {
        if(pc.type !== 'FLAT' && pc.stepSize === null) {
          pc.stepSize = 1
        }
      })

      for(let a in element.restriction) {
        if(element.restriction[a] === null || element.restriction[a] === '') {
          delete element.restriction[a]
        }
        if(a === 'daysOfWeek' && element.restriction[a].length === 0) {
          delete element.restriction[a]
        }
      }

      if(element.edit) {
        this.$set(this.form.rules, element.index, element)
      } else {
        this.form.rules.push(element)
      }


      this.addRuleDialog = false

    },

    deleteRule(element) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.$delete(this.form.rules, element.index)
          this.addRuleDialog = false
        }
      })
    },

    deleteTariff() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          TariffRepository.delete(this.tariffId).then(response => {
            this.$emit('tariff-updated')
            this.addRuleDialog = false
            this.dialog = false
          }).catch(e => {
            this.$dialog.notify.error(e.response.data.message)
          })
        }
      })
    },

    resetForm() {
      this.form = {
        name: null,
        externalReference: null,
        currency: 'EUR',
        network: 'internal',
        url: null,
        richLabel: [
          {
            "language": "fr",
            "value": ""
          },
          {
            "language": "en",
            "value": ""
          },
          {
            "language": "nl",
            "value": ""
          }
        ],
        label: [
          {
            "language": "fr",
            "value": ""
          },
          {
            "language": "en",
            "value": ""
          },
          {
            "language": "nl",
            "value": ""
          }
        ],
        rules: [],
      }
    },

    moveRuleUp(index) {
      const movedItem = this.form.rules.splice(index, 1)[0];
      this.form.rules.splice(index - 1, 0, movedItem);
    },

    moveRuleDown(index) {
      const movedItem = this.form.rules.splice(index, 1)[0];
      this.form.rules.splice(index + 1, 0, movedItem);
    },
  }
}
</script>

<style lang="scss">
  .tariff-modal {

  }

  .tariff-add-rule {
    //.left-col {
    //  //border-right: 1px solid #ccc;
    //  .price-components {
    //    & > .col {
    //      & + .col {
    //        //margin-top: 15px;
    //        //padding-top: 20px;
    //        //border-top: 1px dotted #ccc;
    //      }
    //    }
    //  }
    //}

    .restriction-row {
      & > .col {
        padding: 0 5px;
        & > .row {
          margin: 10px;
          border: 1px dotted #ccc;
          border-radius: 4px;
          min-height: 110px;
          .col-title {
            max-width: 80px;
            align-self: center;
          }
        }
      }
    }
  }
</style>
